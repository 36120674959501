label {
  display: inline-block;
}

.button-holster {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: fit-content;
}

.square:disabled {
  background-color: gray;
}

.square.game-won {
  background-color: yellowgreen;
}

.game {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 75px;
  height: 75px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 75px;
}

.square:focus {
  outline: none;
}