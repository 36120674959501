.menu {
  width: 20rem;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: auto;
  z-index: 1000;
  opacity: 1;
  padding: 3rem 2rem;
}

.menu .closeButton {
  text-decoration: none;
  -moz-transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: 0;
  color: rgba(255, 255, 255, 0.4) !important;
  cursor: pointer;
  display: block;
  height: 3.25rem;
  line-height: 3.25rem;
  padding-right: 1.25rem;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  vertical-align: middle;
  width: 7rem;
  outline: 0;
  background: none;
  box-shadow: none }

.menu .closeButton:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important; }
.menu .closeButton:before {
  content: '\f00d';
  font-size: 1.25rem; }
.menu .closeButton:hover {
  color: #ffffff !important; 
  box-shadow: none; }
@media screen and (max-width: 736px) {
  .menu .closeButton {
    height: 4rem;
    line-height: 4rem; } }

.menu input, .menu select, .menu textarea {
  color: #ffffff; }
.menu a {
  color: #ce1b28; }
.menu strong, .menu b {
  color: #ffffff; }
.menu h1, .menu h2, .menu h3, .menu h4, .menu h5, .menu h6 {
  color: #ffffff; }
.menu blockquote {
  border-left-color: rgba(255, 255, 255, 0.25); }
.menu code {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.25); }
.menu hr {
  border-bottom-color: rgba(255, 255, 255, 0.25); }
.menu input[type="submit"],
.menu input[type="reset"],
.menu input[type="button"],
.menu button,
.menu .button {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #ffffff;
  color: #ffffff !important; }
  .menu input[type="submit"]:hover,
  .menu input[type="reset"]:hover,
  .menu input[type="button"]:hover,
  .menu button:hover,
  .menu .button:hover {
    box-shadow: inset 0 0 0 1px #ce1b28;
    color: #ce1b28 !important; }
    .menu input[type="submit"]:hover:active,
    .menu input[type="reset"]:hover:active,
    .menu input[type="button"]:hover:active,
    .menu button:hover:active,
    .menu .button:hover:active {
      background-color: rgba(0, 0, 0, 0.25); }
  .menu input[type="submit"].primary,
  .menu input[type="reset"].primary,
  .menu input[type="button"].primary,
  .menu button.primary,
  .menu .button.primary {
    box-shadow: none;
    background-color: #ce1b28;
    color: #ffffff !important; }
    .menu input[type="submit"].primary:hover,
    .menu input[type="reset"].primary:hover,
    .menu input[type="button"].primary:hover,
    .menu button.primary:hover,
    .menu .button.primary:hover {
      background-color: #e2212f;
      box-shadow: none; }
      .menu input[type="submit"].primary:hover:active,
      .menu input[type="reset"].primary:hover:active,
      .menu input[type="button"].primary:hover:active,
      .menu button.primary:hover:active,
      .menu .button.primary:hover:active {
        background-color: #b71824; }

.menu > ul {
  margin: 0 0 1rem 0; }
  .menu > ul.links {
    list-style: none;
    padding: 0; }
    .menu > ul.links > li {
      padding: 0; }
      .menu > ul.links > li > a {
        border: 0;
        border-top: solid 1px rgba(255, 255, 255, 0.25);
        color: inherit;
        display: block;
        line-height: 3.5rem;
        text-decoration: none; }
        .menu > ul.links > li > a:hover {
          color: #ffffff; }
      .menu > ul.links > li:first-child > a {
        border-top: 0; }

.menu-enter {
  right: -20rem;
}

.menu-enter-active {
  right: 0;
  transition: 0.5s;
}

.menu-exit {
  right: 0;
}

.menu-exit-active {
  right: -20rem;
  transition: 0.5s;
}

#menuButton {
  color: inherit;
  display: inline-block;
  padding: 0 0.75rem;
  padding-right: 1.25rem;
  text-decoration: none;
  border: none;
  background: none;
  box-shadow: none; 
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.65;
  outline: 0; }
  #menuButton:hover {
    color: #ffffff !important; }
  #menuButton:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
    content: '\f0c9';
    margin: 0 0.5rem 0 0; }